import { VueWizard } from '@/vue-wizard';
import { Component } from 'vue-property-decorator';
import { store } from '@/app/store';
// Components
import AdminNavbar from '@/app/components/admin-navbar/AdminNavbar.vue';
import NavBar from '@/app/components/shared/nav-bar/NavBar.vue';
require('/src/assets/css/style.css');
@Component({
  name: 'administrator-dashboard',
  components: { AdminNavbar, NavBar },
})
export default class AdministratorDashboard extends VueWizard {
  public users = '';
  public selectKey = 2;
  public user: any;

  async created() {
    this.user = store.state.user;

    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'm0pvd5xx',
      email: this.user!.user!.username,
      name:
        this.user!.user!.name!.firstName +
        ' ' +
        this.user!.user!.name!.lastName,
      chatcenter: 'SI',
    };
    (function () {
      let w = window;
      let ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        let d = document;
        let i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        let l = function () {
          let s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/m0pvd5xx';
          let x: any = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          console.log('iniciado desde el admin dashboard');

          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  mounted(): void {
    //TODO: COMENTATO PORQUE NO EXISTE EL ID
  }
  get agentsLength() {
    return store.state.admin!.agents.length;
  }
}
